jQuery(window).on('load',function(){



	//setInterval(top_phone_animation,1100);

	clear_register_form();

});


function clear_register_form(){


	jQuery('.username_reg_input').val()
	
}