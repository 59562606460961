jQuery(window).on('load',function(){


	generate_noty_no_ajax();
	messages_all_counter();

	setInterval(animate_chat,500);
	
});

function animate_chat(){

	jQuery(".chat_column").animate({ scrollTop: $(this).height() }, "slow");

}


function generate(type,text) {
  	var n = noty({
  		text: text,
  		type: type,
		timeout : 5000,
        dismissQueue: true,
		maxVisible: 5,
  		layout: 'topRight',
  		theme: 'defaultTheme'

  	});
 }


function generate_10(type,text) {
  	var n = noty({
  		text: text,
  		type: type,
		timeout : 10000,
        dismissQueue: true,
		maxVisible: 5,
  		layout: 'topRight',
  		theme: 'defaultTheme'

  	});
 }

 function generate_30(type,text) {
	var n = noty({
		text: text,
		type: type,
	  timeout : 30000,
	  dismissQueue: true,
	  maxVisible: 5,
		layout: 'topRight',
		theme: 'defaultTheme'

	});
}

function generate_noty_no_ajax(){

	jQuery( ".messages_notify #message-unsubscribe_freq" ).each( function(){
		generate('error', 'Odczekaj chwilę przed kolejną próbą.');
	});

	jQuery( ".messages_notify #message-not_sended" ).each( function(){
		generate('error', 'Wiadomość nie została wysłana - błąd weryfikacji czy nie jesteś robotem.');
	});


	
	
	jQuery( ".messages_notify #message-email_removed" ).each( function(){
		generate_30('success', 'Jeżeli podany adres znajduje się w bazie, zostanie w najbliższych minutach usunięty. Jeżeli otrzymasz jeszcze jakaś wiadomość od Nas, prosimy o kontakt na info@zakochanapolska.pl załączając wiadomość i adresata( możliwe, że masz ustawione przekierowanie z innego adresu email ). Pozdrawiamy');
	});

	
	jQuery( ".messages_notify #message-banned_user" ).each( function(){
		generate('error', 'Twój profil został zablokowany przez Administrację');
	});

	jQuery( ".messages_notify #message-msg_success" ).each( function(){
		generate('success', 'Wiadomość została wysłana. W sekcji wysłanych wiadomości możesz sprawdzić czy użytkownik ją odczytał.');
	});

	jQuery( ".messages_notify #message-spam_block" ).each( function(){
		generate_10('warning', 'Możliwość pisania wiadomości i wysyłania zaczepek została obecnie zablokowana. Sprawdzimy Twoje konto tak szybko jak to możliwe i usuniemy blokadę lub w przypadku spamu, profil pozostanie zablokowany.');
	});

	jQuery( ".messages_notify #message-blink_success" ).each( function(){
		generate('success', 'Zaczepka została poprawnie wysłana do użytkownika.');
	});

	jQuery( ".messages_notify #message-blank_message" ).each( function(){
		generate('error', 'Wiadomość nie została wysłana. Wiadomość nie może być pusta.');
	});

	jQuery( ".messages_notify #message-spam_message" ).each( function(){
		generate('error', 'Wysyłasz zbyt szybko wiadomości co może świadczyć o spamie( policz do ... ). Prosimy zachować większe odstępy czasu aby nie zostać zablokowanym.');
	});

	jQuery( ".messages_notify #message-user_banned" ).each( function(){
		generate('error', 'Użytkownik został zablokowany');
	});
	
} 



function messages_all_counter(){

	jQuery('.msg-counter p').each( function() {

		jQuery('.msg-counter p').hide();

	    jQuery.ajax({
	      url: '/messages/unread_count',
	      dataType: 'json',
	      success: function(data) {
	        if (data > 0) {
			  jQuery('.msg-counter p').show();
	          jQuery('.msg-counter p').html(data);
	        }
	     }
	    });

  	});


 }