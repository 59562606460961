jQuery(window).on('load',function(){


	fancybox_start();

});

function fancybox_start(){

	jQuery('.more_photos_container .small_photos').each( function(){

			
			jQuery(this).find('a.fancybox').fancybox({

				beforeLoad : function(){
					
					jQuery('body').removeClass('fancybox-active').removeClass('compensate-for-scrollbar');
					
				},
				beforeClose : function(){
					
				}

			});
		
	});

	jQuery('.main_photo_inner').each( function(){

			
			jQuery(this).find('a.fancybox').fancybox({

				beforeLoad : function(){
					
					jQuery('body').removeClass('fancybox-active').removeClass('compensate-for-scrollbar');
					
				},
				beforeClose : function(){
					
				}

			});
		
	});

}
