jQuery(window).on('load',function(){


	setTimeout(function() { 
        clear_register_fields();
    }, 1000);
	scroll_top();
});


jQuery(document).on("ready page:change", function() {

	

});

function scroll_top(){
'use strict';
	
	var d = jQuery('body');
	if (navigator.userAgent.match(/Android|IEMobile|BlackBerry|iPhone|iPad|iPod|Opera Mini/i)) {

			$('.right_top_scroll').bind('touchstart', function (e) {
				jQuery('html,body').animate({ scrollTop: "0px" });
			});
			
	}else{
		
		jQuery('.right_top_scroll').on('click',function(){
			jQuery('html,body').animate({ scrollTop: "0px" });
		});
	
	}

}

function clear_register_fields(){
'use strict';

	if ( jQuery('#error_explanation').length ){

	}else{	

		jQuery('.user_name_field input.username_reg_input').val('');
		jQuery('.email_field input.email_reg_input').val('')
		jQuery('.password_field input.pass_1_reg_input').val('')
		jQuery('.password_2_field input.pass_2_reg_input').val('')

	}
}
