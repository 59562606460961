// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.


import Rails from "@rails/ujs"
//import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
//import "channels" 
import "@fortawesome/fontawesome-free/css/all"

import 'bootstrap'
import '../stylesheets/application'

Rails.start()
//Turbolinks.start()
ActiveStorage.start()

require('jquery')
require('packs/frontpage')
require('packs/noty/jquery.noty')
require('packs/noty/themes/default')
require('packs/noty/layouts/topRight')
require('packs/logged_in')
//require('packs/location')
require('packs/global')
//require('packs/profile_edit')
//require('packs/fancybox/jquery.fancybox.min')
require('packs/profile_show')
require('packs/cookies/simple-cookies')
//import '../js/bootstrap_js_files.js'  